export const immoGTAG = "<!-- Google tag (gtag.js) -->" +
"<script async src=\"https://www.googletagmanager.com/gtag/js?id=G-NVVSMFT9J6\"></script>" +
"<script>" +
"  window.dataLayer = window.dataLayer || [];" +
"  function gtag(){dataLayer.push(arguments);}" +
"  gtag('js', new Date());" +
"" +
"  gtag('config', 'G-NVVSMFT9J6');" +
"</script>";

export const energyGTAG = "<!-- Google tag (gtag.js) -->" +
"<script async src=\"https://www.googletagmanager.com/gtag/js?id=G-NVVSMFT9J6\"></script>" +
"<script>" +
"  window.dataLayer = window.dataLayer || [];" +
"  function gtag(){dataLayer.push(arguments);}" +
"  gtag('js', new Date());" +
"" +
"  gtag('config', 'G-XFRVCCY05L');" +
"</script>"; 