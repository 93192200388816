import { getCookie } from "./getCookie";
import { immoGTAG, energyGTAG } from "./gtags"

export default () => {
  const cookiebanner = document.getElementById("cookie-banner");
  let brickwiseCookieConsentgtagObject = {
    ad_storage: false,
    analytics_storage: false,
    personalization_storage: false,
  };

  function setBrickwiseCookieConsent(options) {
    let cookieConent = JSON.stringify(options);
    const d = new Date();
    d.setTime(d.getTime() + 180 * 24 * 60 * 60 * 1000);
    const expires = "expires=" + d.toUTCString();
    document.cookie =
      "brickwiseCookieConsentgGtm" + "=" + cookieConent + ";" + expires + ";path=/";
  }

  const brickwiseCookieConsentgGtm = getCookie("brickwiseCookieConsentgGtm");
  if (!brickwiseCookieConsentgGtm) {
    cookiebanner.style.display = "block";
  } else {
    brickwiseCookieConsentgtagObject = JSON.parse(brickwiseCookieConsentgGtm);
    setupGTAGManager(brickwiseCookieConsentgtagObject);
  }

  function setupGTAGManager(brickwiseCookieConsentgtagObject) {
    if (brickwiseCookieConsentgtagObject.ad_storage || brickwiseCookieConsentgtagObject.analytics_storage) {
      let addToHeader = ''
      if (window.location.hostname.includes("brickwise.immo")) addToHeader = immoGTAG
      if (window.location.hostname.includes("brickwise-energy")) addToHeader = energyGTAG

      var head = document.getElementsByTagName("head");
      head[0].innerHTML += addToHeader
    }
  }

  // Interactions with the cookie banner
  const allClick = document.getElementById("btn_cookie_all");
  const noneClick = document.getElementById("btn_cookie_none");
  const specificClick = document.getElementById("btn_cookie_save");
  allClick.addEventListener("click", handleAllClick, false);
  noneClick.addEventListener("click", handleSpecificClick, false);
  specificClick.addEventListener("click", handleSpecificClick, false);

  function handleAllClick() {
    brickwiseCookieConsentgtagObject = {
      ad_storage: true,
      analytics_storage: true,
      personalization_storage: true,
    };
    setBrickwiseCookieConsent(brickwiseCookieConsentgtagObject);
    setupGTAGManager(brickwiseCookieConsentgtagObject);
    document.getElementById("cookie-banner").style.display = "none";
  }

  function handleSpecificClick() {
    const performance = document.getElementById("performance-checkbox").checked;
    const marketing = document.getElementById("advertisment-checkbox").checked;
    if (performance && marketing) {
      brickwiseCookieConsentgtagObject = {
        ad_storage: true,
        analytics_storage: true,
        personalization_storage: true,
        functionality_storage: true,
      };
      setBrickwiseCookieConsent(brickwiseCookieConsentgtagObject);
      setupGTAGManager(brickwiseCookieConsentgtagObject);
    } else if (marketing && !performance) {
      brickwiseCookieConsentgtagObject.ad_storage = true;
      setBrickwiseCookieConsent(brickwiseCookieConsentgtagObject);
      setupGTAGManager(brickwiseCookieConsentgtagObject);
    } else if (!marketing && performance) {
      brickwiseCookieConsentgtagObject.analytics_storage = true;
      setBrickwiseCookieConsent(brickwiseCookieConsentgtagObject);
      setupGTAGManager(brickwiseCookieConsentgtagObject);
    } else if (!marketing && !performance) {
      brickwiseCookieConsentgtagObject.functionality_storage = false;
      setBrickwiseCookieConsent(brickwiseCookieConsentgtagObject);
      setupGTAGManager(brickwiseCookieConsentgtagObject);
    } else {
    }
    document.getElementById("cookie-banner").style.display = "none";
  }
};
