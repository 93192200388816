import cookieConsent from "./cookieConsent";

(() => {

  $(document).ready(function () {
    console.log('Load Header Module')

    Webflow.push(function () {
      console.log('Load Header Module cookies')
      $(document).ready(function () {
        cookieConsent();
      });
    });

    Webflow.push(function () {
      $(document).ready(function () {
        // Custom Code here
      });
    });
  })
})();
